<template>
  <span :title="content" ref="el" role="button">
    <slot></slot>
  </span>
</template>

<script>
import { Tooltip } from 'bootstrap';

export default {
  name: 'BootstrapTooltip',
  props: {
    content: {
      type: String,
      required: true,
    },
    placement: {
      type: String,
      default: 'top',
    },
  },
  data() {
    return {
      tooltip: null,
    };
  },
  mounted() {
    const { el } = this.$refs;
    const { placement } = this;

    this.tooltip = new Tooltip(el, {
      animation: false,
      placement,
    });

    ['show', 'shown', 'hide', 'hidden', 'inserted'].forEach((e) => {
      el.addEventListener(`${e}.bs.tooltip`, () => {
        this.$emit(e);
      });
    });

    el.addEventListener('mouseleave', () => {
      this.tooltip.hide();
    });
  },
  beforeDestroy() {
    this.tooltip.dispose();
  },
};
</script>
